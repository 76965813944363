import React from 'react';
import CountUp from "react-countup";

function Stat({label, type, value, prefix}: {label: string; type?: string; value: number | string; prefix?: string;}) {
  return (
    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3 mx-1">
      <div className="d-flex align-items-center">
        {type == 'counter' ? (
            <CountUp end={Math.floor(value as number)} prefix={prefix ? `${prefix} ` : undefined} className={'fs-4 fw-bold'} />
          ) : (
          <div className="fs-4 fw-bold">{value}</div>
        )}
      </div>
      <div className="fw-semibold fs-6 text-gray-400">{label}</div>
    </div>
  );
}

export default Stat;
